import ReactPlayerLoader from '@brightcove/react-player-loader'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import styles from './video.module.scss'

import Seo from '@/components/general/seo'
import Layout from '@/components/layout/layout'
import { Navigation } from '@/components/video/navigation'
import ViewingEnv from '@/components/video/viewing-env'
import { ACCOUNT_ID } from '@/lib/constants'
import { BreadcrumbContext } from '@/types/breadcrumb'

const Page: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo title="臨床医向け認知評価" />
      <Helmet>
        {/* <!-- Ptengine Tag --> */}
        <script src="https://js.ptengine.jp/67a5keif.js"></script>
        {/* End Ptengine Tag */}
      </Helmet>
      <div className={styles.wrap}>
        <h1 className={styles.title}>第1回&nbsp;&nbsp;臨床医向け認知評価</h1>
        <ReactPlayerLoader
          attrs={{ className: styles.video }}
          accountId={ACCOUNT_ID}
          videoId="6303890662001"
          playerId="hM1SBfBel"
          embedOptions={{ responsive: 'aspectRatio' }}
        />
        <div className={styles.videoBody}>
          <div className={styles.speaker}>
            <p>
              <span>ファシリテーター</span>
              <span>Lakshmi N. Yatham 教授</span>
            </p>
            <p>
              <span>エキスパート</span>
              <span>
                Kamilla Miskowiak 教授( コペンハーゲン大学、デンマーク)
              </span>
            </p>
          </div>
          <Navigation next="2" />
          <div className={styles.paragraph}>
            <p>
              認知機能とは何か、認知機能に含まれる領域とは何か、医師がどうして精神疾患を持つ人の認知機能評価を気にかける必要があるのか、そして精神疾患の認知機能の評価尺度にはどのようなものがあるのか。
              <br />
              精神疾患における認知機能の基礎と評価方法を学ぶことができます。
            </p>
            <p>大日本住友製薬（現：住友ファーマ株式会社）</p>
          </div>
          <ViewingEnv />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={'臨床医向け認知評価'}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Page
